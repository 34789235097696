<template>
  <div
    v-if="!videoMetaDataLoading"
    class="video-controls"
  >
    <div class="video-progress-text">
      {{playedVideoDuration}} / {{ totalVideoDuration }}
    </div>

    <div class="play-pause-rewind-block">
      <div class="media-control">
        <button
          class="media-control-btn"
          @click="jumpToTargetBlock('prev')"
        >
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.333 6.333l-.53.53a.75.75 0 001.28-.53h-.75zM1.666 3.667l-.53-.53a.75.75 0 000 1.06l.53-.53zM4.333 1h.75a.75.75 0 00-1.28-.53l.53.53zM9 3.667v-.75.75zM8.333 8.25a.75.75 0 000 1.5v-1.5zm-3.47-2.447L2.198 3.136l-1.06 1.061 2.666 2.667 1.06-1.061zM2.198 4.197L4.864 1.53 3.803.47 1.136 3.136l1.06 1.061zM3.583 1v5.333h1.5V1h-1.5zM1.666 4.417H9v-1.5H1.667v1.5zm7.334 0c.508 0 .996.202 1.355.561l1.06-1.06C10.776 3.276 9.907 2.916 9 2.916v1.5zm1.355.561c.36.36.561.847.561 1.355h1.5c0-.906-.36-1.775-1-2.416l-1.06 1.061zm.561 1.355c0 .509-.201.996-.56 1.356l1.06 1.06c.64-.64 1-1.51 1-2.416h-1.5zm-.56 1.356c-.36.36-.848.561-1.356.561v1.5c.906 0 1.775-.36 2.416-1l-1.06-1.061zM9 8.25h-.667v1.5H9v-1.5z" fill="#555775"/></svg>
        </button>
        <div class="media-control-tooltip">{{staticText.moveToPrevBlockTooltip}}</div>
      </div>
      <div class="media-control">
        <button
          class="media-control-btn"
          @click="jumpBackward"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.5 9a.47.47 0 00-.48.5A6 6 0 108 3H6v-.932a.5.5 0 00-.82-.385L3.486 3.095a.5.5 0 00.02.784L5.2 5.15a.5.5 0 00.8-.4V4h2a5 5 0 11-4.975 5.5A.542.542 0 002.5 9z" fill="#555775"/><path d="M6.914 7H6.17c-.144.528-.372.66-1.17.666v.552h1.056v2.742h.858V7zM9.173 7.702h1.632V7H8.537l-.246 2.19h.828a.64.64 0 01.462-.192c.348 0 .564.252.564.648 0 .414-.228.69-.582.69-.294 0-.486-.18-.528-.498h-.864c.084.768.576 1.2 1.386 1.2.882 0 1.44-.546 1.44-1.398 0-.798-.516-1.32-1.302-1.32-.222 0-.39.03-.606.108l.084-.726z" fill="#555775"/></svg>
        </button>
        <div class="media-control-tooltip">{{staticText.jumpBackwardTooltip}} </div>
      </div>
      <div class="media-control">
        <button
          class="media-control-btn play-pause-btn"
          @click="togglePlayPause"
        >
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isVideoPlaying" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0A1.5 1.5 0 000 1.5v13a1.5 1.5 0 003 0v-13A1.5 1.5 0 001.5 0zm9 0A1.5 1.5 0 009 1.5v13a1.5 1.5 0 003 0v-13A1.5 1.5 0 0010.5 0z" fill="#35375B"/></svg>
          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.235.192A1.5 1.5 0 000 1.5v9a1.5 1.5 0 002.235 1.307l8-4.5a1.5 1.5 0 000-2.614l-8-4.5V.192z" fill="#2A2D52"/></svg>
        </button>
        <div class="media-control-tooltip">{{isVideoPlaying?  staticText.videoPauseLabel: staticText.videoPlayLabel}}</div>
      </div>
      <div class="media-control">
        <button
          class="media-control-btn"
          @click="jumpForward"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.5 9a.47.47 0 01.48.5A6 6 0 118 3h2v-.932a.5.5 0 01.82-.385l1.694 1.412a.5.5 0 01-.02.784L10.8 5.15a.5.5 0 01-.8-.4V4H8a5 5 0 104.975 5.5.542.542 0 01.525-.5z" fill="#555775"/><path d="M6.914 7H6.17c-.144.528-.372.66-1.17.666v.552h1.056v2.742h.858V7zM9.173 7.702h1.632V7H8.537l-.246 2.19h.828a.64.64 0 01.462-.192c.348 0 .564.252.564.648 0 .414-.228.69-.582.69-.294 0-.486-.18-.528-.498h-.864c.084.768.576 1.2 1.386 1.2.882 0 1.44-.546 1.44-1.398 0-.798-.516-1.32-1.302-1.32-.222 0-.39.03-.606.108l.084-.726z" fill="#555775"/></svg>
        </button>
        <div class="media-control-tooltip">{{staticText.jumpForwardTooltip}}</div>
      </div>
      <div class="media-control">
        <button
          class="media-control-btn"
          @click="jumpToTargetBlock('next')"
        >
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 6.333h-.75a.75.75 0 001.28.53L9 6.334zm2.666-2.666l.53.53a.75.75 0 000-1.06l-.53.53zM9 1l.53-.53A.75.75 0 008.25 1H9zM4.333 3.667v-.75.75zM1.666 6.333h-.75.75zM5 9.75a.75.75 0 000-1.5v1.5zm4.53-2.886l2.667-2.667-1.06-1.06L8.47 5.802l1.06 1.06zm2.667-3.728L9.53.47 8.47 1.53l2.666 2.667 1.06-1.06zM8.25 1v5.333h1.5V1h-1.5zm3.416 1.917H4.333v1.5h7.333v-1.5zm-7.333 0c-.906 0-1.775.36-2.416 1l1.06 1.061c.36-.36.848-.561 1.356-.561v-1.5zm-2.416 1c-.64.641-1 1.51-1 2.416h1.5c0-.508.201-.996.56-1.355l-1.06-1.06zm-1 2.416c0 .906.36 1.776 1 2.416l1.06-1.06a1.917 1.917 0 01-.56-1.356h-1.5zm1 2.416c.641.641 1.51 1.001 2.416 1.001v-1.5c-.508 0-.996-.202-1.355-.561l-1.06 1.06zM4.333 9.75H5v-1.5h-.667v1.5z" fill="#555775"/></svg>
        </button>
        <div class="media-control-tooltip">{{staticText.moveToNextBlockTooltip}}</div>
      </div>
    </div>

    <div class="speed-jump-block">
      <b-form-group class="position-relative">
        <b-form-select
          ref="videoSpeedSelect"
          id="videospeed232323"
          v-model="videoPlaybackRate"
          class="h-100 custom-form-select"
          plain
          :options="videoPlaybackRateList"
          @change="changeVideoPlaybackRate"
        >
        </b-form-select>
        <img
          src="@/assets/svgs/speedometer.svg"
          class="speedometer-icon"
          alt="Playback Speed"
          @click="triggerSelect"
        />
      </b-form-group>

      <div
        class="end-video-btn"
        role="button"
        @click="jumpToEndOfVideo"
      >
        <span class="">{{staticText.toTheEndLabel}}</span>
        <img src="@/assets/svgs/to-end.svg" height="14px"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "VideoControls",
  data () {
    return {
      videoPlaybackRate: 1,
      videoPlaybackRateList: [
        { value: 0.5, text: "0.5x" },
        { value: 1, text: "1x" },
        { value: 1.25, text: "1.25x" },
        { value: 1.5, text: "1.5x" },
        { value: 1.75, text: "1.75x" },
        { value: 2, text: "2x" }
      ],
      JUMP_DURATION: 15, // 15 seconds,
      staticTextDefault: {
        videoPauseLabel: "pause",
        videoPlayLabel: "play",
        moveToPrevBlockTooltip: "move to previous block",
        moveToNextBlockTooltip: "move to next block",
        jumpBackwardTooltip: "15 seconds backward",
        jumpForwardTooltip: "15 seconds forward",
        toTheEndLabel: "To the end"
      }
    }
  },
  props: {
    videoMetaDataLoading: {
      type: Boolean
    },
    isVideoPlaying: {
      type: Boolean
    },
    totalDuration: {
      type: Number
    },
    currentVideoTime: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      meetingTranscription: "callVideoStore/getMeetingTranscript"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    playedVideoDuration () {
      return this.formatTime(this.currentVideoTime)
    },
    totalVideoDuration () {
      return this.formatTime(this.totalDuration)
    }
  },
  methods: {
    formatTime (seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${hours.toString().padStart(2, "")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
    },
    changeVideoPlaybackRate () {
      this.$emit("change-video-playback-rate", this.videoPlaybackRate)
    },
    togglePlayPause () {
      this.$emit("toggle-play-pause")
    },
    jumpToEndOfVideo () {
      this.$emit("jump-to-end-of-video")
    },
    jumpBackward () {
      this.$emit("jump-backward", this.JUMP_DURATION)
    },
    jumpForward () {
      this.$emit("jump-forward", this.JUMP_DURATION)
    },
    jumpToTargetBlock (type) {
      this.$emit("jump-to-target-block", type)
    },
    convertTimestampToSeconds (timestamp) {
      const [minutes, seconds] = timestamp.split(":").map(Number)
      return (minutes * 60) + seconds
    },
    triggerSelect () {
      this.$refs.videoSpeedSelect.$el.showPicker()
    }
  }
}
</script>

<style lang="scss" scoped>

.video-controls {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .play-pause-rewind-block {
    grid-area: 1 / 1 / 2 / 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .media-control {
      display: flex;
      align-items: center;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .play-pause-btn {
      width: 48px;
      height: 48px;
      &:hover {
        + .media-control-tooltip {
          visibility: visible;
        }
      }
      &:hover {
        svg {
          path {
            fill: #FF5632;
          }
        }
      }
    }
  }

  .video-progress-text {
    grid-area: 2 / 1 / 3 / 4;
    align-self: center;
    color: #555775;
    line-height: 14px;
  }

  .speed-jump-block {
    grid-area: 2 / 4 / 3 / 8;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    :deep(.form-group){
      margin-bottom: 0;
    }

    .custom-form-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      box-shadow: none;
      background-color: #EFEFEF;
      color: #555775;
      padding: 7px 7px 7px 25px !important;
      line-height: 14px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    .speedometer-icon {
      position: absolute;
      left: 8px;
      height: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      pointer-events: auto;
    }

    .end-video-btn {
      background-color: #EFEFEF;
      display: flex;
      align-items: center;
      border-radius: 100px;
      padding: 7px 16px;
      line-height: 14px;
      color: #555775;
      margin-left: 16px;
      span {
        margin-right: 8px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .play-pause-rewind-block {
      justify-content: center;
      margin: 0;
    }
  }
}

</style>
