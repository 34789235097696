import { render, staticRenderFns } from "./VideoControls.vue?vue&type=template&id=1510bc17&scoped=true&"
import script from "./VideoControls.vue?vue&type=script&lang=js&"
export * from "./VideoControls.vue?vue&type=script&lang=js&"
import style0 from "./VideoControls.vue?vue&type=style&index=0&id=1510bc17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1510bc17",
  null
  
)

export default component.exports